<template>
  <div id="app">
    <div class="loader-overlay" v-if="isProcessing">
      <div class="loader"></div>
      <p>Processing...</p>
    </div>
    <div class="container mx-auto py-5">
      <div id="logo">
        <img :src="require(`@/assets/images/logo.png`)"  alt="App Shot Maker" />

      </div>


      <div id="app-wrapper" class="my-8 bg-white rounded">
        <div id="tabs">
          <div class="tab-body flex p-10 bg-gray-300 rounded shadow">

            <!-- Screenshot Scene -->
            <scene/>

            <div class="settings-wrapper p-8 bg-gray-200 ml-4 rounded flex-1">
              <div class="mb-4">
                <text-input label="Text" id="text" type="text" placeholder="Please enter text" v-model="scene.text"/>
              </div>
              <div class="flex mb-4">
                <div class="flex-1 mr-3">
                  <text-input label="Font Size" id="font-size" type="number" placeholder="Please enter font size"
                              v-model="scene.fontSize"/>
                </div>
                <div class="flex-1 ml-3">
                  <color-picker :color="scene.textColor.hex8" label="Text Color" v-model="scene.textColor.hex8"/>
                </div>
              </div>
              <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="text">
                  Phone
                </label>

                <div class="relative">
                  <select
                      class="shadow block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="scene.phone">
                    <option value="nexus">Nexus</option>
                    <option value="iphone">iPhone</option>
                    <option value="nexus_black">Nexus Black</option>
                  </select>
                  <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20">
                      <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="flex mb-4 justify-between">
                <div class="flex-1 ">
                  <label class="block text-gray-700 text-sm font-bold mb-2" for="">
                    Font Family
                  </label>
                  <font-picker :api-key="'AIzaSyD7avrU2kESJyd_qubtHgTGtN-mAD_fzto'"
                               :active-font="scene.fontFamily" :sort="'popularity'" @change="updateFont">
                  </font-picker>
                </div>
              </div>
              <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="">
                  Background Color
                </label>
                <div class="solid-colors">
                  <div class="my-5">
                    <color-picker :color="scene.bgColor.hex8" v-model="scene.bgColor.hex8"/>

                    <!-- Gradients -->
                    <div class="static-bg-colors flex flex-wrap justify-between mt-5 pt-2 border-t">
                      <button class="bg-color-box w-24 h-20 my-3 mx-2 rounded shadow"
                              :style="{'background': color}" v-for="(color, key) in staticGradients"
                              :key="key" @click="updateBgColor(color)"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="text-justify text-white text-lg leading-8">
        <div class="my-2 text-white leading-8">
          <h2 class="font-bold text-2xl ">The All-in-One App Screenshot Maker</h2>
          <p>
            Create high-quality, professional mockups for your Apple and Android apps simultaneously, with ease. Achieve
            designer-level visuals without the need for advanced design skills—everything is streamlined to simplify the
            process, giving your apps a polished and professional look.
          </p>
        </div>
        <div class="mb-2 text-white  leading-8">
          <h2 class="font-bold text-2xl   ">
            Quickly Generate App Store & Play Store Screenshots
          </h2>
          <p>
            Create App Store & Play Store Screenshots in Minutes Leverage a single, responsive design to generate app
            store-ready images tailored for all iOS and Android devices.
            Whether you're targeting App Store Connect or Google Play, you'll receive pixel-perfect screenshots in PNG
            formats, optimized for every screen size and resolution. With intuitive controls and fast export options,
            your screenshot will be ready for upload in no time.
          </p>
        </div>
        <div class="mb-2">
          <h2 class="font-bold text-2xl  my-3">
            Frequently Asked Questions (FAQ)
          </h2>
          <div class="my-2">
            <strong class="text-orange-300">
              How many screenshots can you have on the Apple App and Play Store?
            </strong>
            <p>
              For the Play Store, you can upload a minimum of 2 screenshots and a maximum of 8 for each supported device type (phone, tablet, Android TV, and Wear OS). It's recommended to showcase different aspects of the app in these screenshots. On the App Store, you can upload up to 10 screenshots for each supported device type (iPhone, iPad, Apple Watch, Apple TV, and Mac). This allows you to showcase various features of your app across different devices.
            </p>
          </div>
          <div class="my-2">
            <strong class="text-orange-300">
              How do you make a screenshot for the iOS Apple App Store and Android Google Play Store?
            </strong>
            <p>
              With AppShotMaker, design and deploy with confidence. Our editor crafts screenshots that align seamlessly with the stipulations of both the Apple App
            </p>
          </div>
          <div class="my-2">
            <strong class="text-orange-300">
              How do you make a screenshot for the iOS Apple App Store and Android Google Play Store?
            </strong>
            <p>
              With AppShotMaker, design and deploy with confidence. Our editor crafts screenshots that align seamlessly with the stipulations of both the Apple App Store and Google Play Store. While thousands of users trust AppShotMaker every week, remember that both stores can be meticulous about screenshot formats. Therefore it's important to gather specific screenshots for each targeted device and adjust based on any feedback from Apple or Google.
            </p>
          </div>
          <div class="my-2">
            <strong class="text-orange-300">
              Why invest time into my screenshots?
            </strong>
            <p>
              First impressions can make or break your app’s success. Nearly 60% of potential users make a download decision based solely on your app’s name, rating, and those initial screenshots. In fact, a mere tweak in your app store presentation can skyrocket conversions by up to 35%. Intrigued? Dive deep into this insight and more from a detailed analysis of over 500 million app store visitors at <a class="underline text-blue-300" rel="nofollow" target="_blank" href="https://www.storemaven.com/app-store-statistics-revealed/">Storemaven</a> .
            </p>
          </div>

        </div>

      </footer>

    </div>


  </div>
</template>

<script>
import FontPicker from 'font-picker-vue';
import {
  Chrome
} from 'vue-color'
import gradientColors from '@/configs/gradients';
import GithubButton from 'vue-github-button';
import Scene from '@/components/Scene'
import ColorPicker from '@/components/ColorPicker'
import TextInput from '@/components/TextInput'
import {mapState} from 'vuex';
import {mixin as clickaway} from 'vue-clickaway';


export default {
  data() {
    return ({
      textPickerStatus: false,
      bgPickerStatus: false,
      staticGradients: gradientColors
    })
  },
  mixins: [clickaway],
  components: {
    FontPicker,
    ColorPickers: Chrome,
    ColorPicker,
    GithubButton,
    Scene,
    TextInput
  },
  computed: {
    ...mapState({
      scene: state => state.scene,
      isProcessing: state => state.isProcessing
    })
  },
  methods: {
    updateFont(font) {
      this.$store.commit('updateFontFamily', font.family)
    },
    updateBgColor(color) {
      this.$store.commit('updateBgColor', color)
    },
    closeAllPickers() {
      this.textPickerStatus = false
      this.bgPickerStatus = false
    },
    showPicker(pickerName) {
      document.addEventListener('click', this.documentClick);
      this[`${pickerName}PickerStatus`] = true;
    },
    hidePickers() {
      document.addEventListener('click', this.documentClick);
      this.textPickerStatus = false;
      this.bgPickerStatus = false;
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
          target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Fugaz+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');


#app {
  width: 1280px;
  margin: 0 auto;

  .container {
    width: 100%;
    max-width: inherit;
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

nav {
  border-color: rgba(0, 0, 0, 0.1);
}

#logo {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  font-family: 'Fugaz One', cursive;
  color: white;
  img{
    width: 350px;
    display: block;
    margin: auto;
  }
}

</style>
